<template>
    <el-dialog
        v-loading="saving"
        :title="`发布需求信息 -${type === 'COPY' ? '版权' : '软著'}`"
        center
        :visible.sync="show"
        width="980px"
    >
        <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-width="200px"
            label-position="right"
            size="small"
            inline
        >
            <el-form-item prop="name" :label="`${type === 'COPY' ? '需求作品名称' : '需求软著名称'}`">
                <el-input style="width: 200px" v-model="formData.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="workType" :label="`${type === 'COPY' ? '需求作品类型' : '需求软著类型'}`">
                <el-select v-model="formData.workType" clearable filterable placeholder="请选择">
                    <el-option
                        v-for="item in workTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="description" label="需求描述" class="block">
                <el-input
                    type="textarea"
                    v-model="formData.description"
                    maxlength="500"
                    show-word-limit
                    :autosize="{ minRows: 6, maxRow: 6 }"
                    placeholder="请填写需求描述，500字以内"
                    resize="none"
                ></el-input>
            </el-form-item>
            <el-form-item label="交易方式" prop="modeId">
                <el-radio-group v-model="formData.modeId">
                    <el-radio v-for="item in tradingMethodOption" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="expectedPrice" label="预算(万元)">
                <el-input-number
                    v-modeIdl="formData.expectedPrice"
                    :disabled="formData.negotiateDirectly"
                    @change="Price"
                    :precision="1"
                    :controls="false"
                    placeholder="请输入"
                    style="margin-right: 10px"
                >
                </el-input-number>
                <el-checkbox @change="Directly" v-model="formData.negotiateDirectly">面议</el-checkbox>
            </el-form-item>

            <el-form-item prop="contact" label="联系人">
                <el-input v-model="formData.contact" placeholder="请输入联系人"></el-input>
            </el-form-item>

            <el-form-item prop="phone" label="联系电话">
                <el-input style="width: 200px" v-model="formData.phone" placeholder="请输入联系电话"></el-input>
            </el-form-item>

            <el-form-item prop="address" label="所在地区">
                <el-input style="width: 400px" v-model="formData.address" placeholder="请输入所在地址"></el-input>
            </el-form-item>

            <el-form-item prop="email" label="邮箱">
                <el-input style="width: 200px" v-model="formData.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
        </el-form>
        <div class="btns">
            <el-button type="info" size="small" @click="show = false">返回</el-button>
            <el-button type="primary" size="small" @click="onSave">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
// import RichText from '../RichText.vue';
import { tradingMethodOption, phonePattern } from '../../utils/variables';
import { mapState } from 'vuex';
export default {
    // components: { RichText },
    name: 'CopyrightDemandEdit',
    created() {
        console.log("fsdrdr",this.tradingMethodOption)
    },
    data() {
        return {
            needChange: true,
            show: false,
            saving: false,
            formData: {},

            workTypeOptions: [],
            tradingMethodOption
        };
    },
    computed: {
        ...mapState(['userInfo']),
        type() {
            return this.$route.query.type;
        },
        rules() {
            return {
                name: [
                    {
                        required: true,
                        message: `请输入需求${this.type === 'COPY' ? '作品' : '软著'}名称`
                    }
                ],
                // workType: [{ required: true, message: `请选择需求${this.type === 'COPY' ? '作品' : '软著'}类型` }],
                // description: [{ required: true, message: '请填写需求描述' }],
                // modeId: [{ required: true, message: '请选择交易方式' }],
                // expectedPrice: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             if (!this.formData.negotiateDirectly) {
                //                 if (!this.formData.expectedPrice) {
                //                     callback(new Error('请选择预算方式'));
                //                 } else {
                //                     callback();
                //                 }
                //             } else {
                //                 callback();
                //             }
                //         }
                //     }
                // ],
                // contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                // phone: [{ required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' }],
                // email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                // address: [{ required: true, message: '请输入所在地区' }]
            };
        }
    },
    methods: {
        Price() {
            if (this.formData.expectedPrice) {
                this.formData.negotiateDirectly = false;
            }
        },
        Directly() {
            if (this.formData.negotiateDirectly == true) {
                this.$set(this.formData, 'expectedPrice', undefined);
            }
            this.$forceUpdate();
        },
        init(type = 'SOFT') {
            this.$http
                .post('/setting/byFlag', { flag: this.$route.query.type == 'COPY' ? 3 : 4 })
                .then(res => {
                    this.workTypeOptions = res.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                this.formData = {
                    userId: this.userInfo.id,
                    type: type,
                    // mode: 'TRANSFER',
                    contact: this.userInfo.nickname,
                    negotiateDirectly: false,
                    phone: this.userInfo.phone,
                    email: this.userInfo.email
                };
            });
        },
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.init(this.type);
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData, status: 'PENDING' };
            // console.log(data);
            this.saving = true;
            this.$http
                .post('/copyrightDemand/save', data, { body: 'json' })
                .then(res => {
                    // console.log(res);
                    this.saving = false;
                    this.$message.success('发布成功');
                    this.show = false;
                    this.$emit('refreash');
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog .el-form-item {
    width: 100%;
}
.el-tag {
    margin-bottom: 11px;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.input-new-tag {
    width: 100%;
    vertical-align: bottom;
}
/deep/ .el-checkbox__inner {
    border-radius: 50%;
}
/deep/ .el-dialog__header{
    padding: 17px 0px !important;
}
/deep/ .el-form{
    width: 100% !important;
    margin: 0px !important;
}

</style>
